import tailwindConfig from '../../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'

export const theme = resolveConfig(tailwindConfig).theme

/** Do not use directly, use TailwindContext instead */
export function getBreakpointInPixels (breakpoint) {
  const value = theme.screens[breakpoint]
  return Number(value.substring(0, value.length - 2))
}

import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import './style.css'

import App from './App'

const HOSTNAMES = {
  STAGING: 'isotra-staging.k8s.cognito.cz',
  DEV: 'isotra-dev.k8s.cognito.cz',
  PORTAL: 'portal.isotra.com',
  LOCALHOST: 'isotra.app.localhost'
}

const ENVIROMENTS = {
  STAGING: 'staging',
  DEV: 'dev',
  PORTAL: 'production'
}

if (window.location.hostname !== HOSTNAMES.LOCALHOST) {
  const environment = Object.keys(HOSTNAMES).find(key => HOSTNAMES[key] === window.location.hostname)
    ? ENVIROMENTS[Object.keys(HOSTNAMES).find(key => HOSTNAMES[key] === window.location.hostname)]
    : null

  Sentry.init({
    dsn: 'https://3e6c6e279f8b48c25d988a4b38778426@o4508822809280512.ingest.de.sentry.io/4508822980919376',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https?:\/\/isotra\.app\.localhost\//,
      /^https?:\/\/portal\.isotra\//,
      /^https?:\/\/isotra-dev\.k8s\.cognito\//,
      /^https?:\/\/isotra-staging\.k8s\.cognito\//
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment
  })
}

const container = document.getElementById('app')
const root = createRoot(container, {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack)
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler()
})

root.render(<App />)

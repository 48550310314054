import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
  const [activeKeys, setActiveKeys] = useState([])
  const value = { activeKeys, setActiveKeys }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
}

import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import './style.css'

import App from './App'

if (window.location.hostname !== 'isotra.app.localhost') {
  Sentry.init({
    dsn: 'https://d49d2de639474e969b765777ff03f0a0@sentry.cognito.cz/139',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https?:\/\/isotra\.app\.localhost\//,
      /^https?:\/\/portal\.isotra\//,
      /^https?:\/\/isotra-dev\.k8s\.cognito\//,
      /^https?:\/\/isotra-staging\.k8s\.cognito\//
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

const container = document.getElementById('app')
const root = createRoot(container, {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack)
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler()
})

root.render(<App />)

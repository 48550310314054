import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'

import { TailwindContext } from './context/TailwindContext'
import { theme, getBreakpointInPixels } from './services/tailwind'
import { AppProvider } from './context/AppContext'

const In = React.lazy(() => import('./pages/in/In'))
const Auth = React.lazy(() => import('./pages/auth/Auth'))
const AnonymousConfigurator = React.lazy(() => import('./pages/AnonymousConfigurator'))
const Drawing = React.lazy(() => import('./pages/Drawing'))

const queryClient = new QueryClient()

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/in/*" element={<React.Suspense><In /></React.Suspense>} />
      <Route path="/anonymous-configurator/*" element={<React.Suspense><AnonymousConfigurator /></React.Suspense>} />
      <Route path="/drawing" element={<React.Suspense><Drawing /></React.Suspense>} />
      <Route path="/:locale/*" element={<React.Suspense><Auth /></React.Suspense>} />
      <Route path="*" element={<Navigate to="/cs" />} />
    </>
  )
)

export default function App () {
  return (
    <TailwindContext.Provider value={{ theme, getBreakpointInPixels }}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <RouterProvider router={router} />
          <div id="body-portal" />
        </AppProvider>
      </QueryClientProvider>
    </TailwindContext.Provider>
  )
}
